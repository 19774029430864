<template>
	<div class="solve-page">
		<div class="solve-warpper">
			<div class="top-section">
				<div class="container">
					<div class="top-box">
						<div class="top-title">如何4个月拿到 2023 秋招 FMAG Offer</div>
						<div class="more-content">23级8月新生入学，22级最后全职机会，解决三大核心问题</div>
            <div class="sec-title-me">Facebook Microsoft Amazon Google</div>
					</div>
				</div>
			</div>
			<div class="solve-main">
				<div class="section">
					<div class="container">
						<div class="sec-head">
							<div class="sec-en">How to plan</div>
							<div class="sec-title">
								<div class="sec-inner">一，如何规划"北美CS留学-FMAG求职时间线" </div>
							</div>
<!--							<div class="sec-desc">推荐硬核，工业界项目课程，不再是Udemy，学校等水项目课程</div>-->
						</div>
						<div class="sec-body">
							<el-row :gutter="40">
								<el-col :span="12">
									<div class="how-box">
										<img src="../../assets/images/class/plan1.png" alt="" class="how-icon">
										<div class="how-tt">根据学制的时间安排</div>
										<div class="how-desc">根据美本，Master的1年，2年的学制安排求职规划 </div>
									</div>
								</el-col>
								<el-col :span="12">
									<div class="how-box">
										<img src="../../assets/images/class/plan2.png" alt="" class="how-icon">
										<div class="how-tt">实习 & 全职规划</div>
										<div class="how-desc">投实习，找全职时间，转码，是否延期毕业等全方位规划</div>
									</div>
								</el-col>
							</el-row>
						</div>
					</div>
				</div>
				<div class="section">
					<div class="container">
						<div class="sec-head">
							<div class="sec-en">How to get it</div>
							<div class="sec-title">
								<div class="sec-inner">二，如何拿到 FMAG 面试机会</div>
							</div>
						</div>
						<div class="sec-body">
							<el-row :gutter="66">
								<el-col :span="12">
									<div class="fmag-box">
										<div class="fmag-content">
											<div class="fmag-head">
												<div class="fmag-title">
													完善的简历
												</div>
											</div>
											<div class="fmag-desc">
                        <p>1，工业级的项目，非学校 & Udemy类似的水项目</p>
												<p>2，全部是CS相关项目，而不是非CS专业的内容</p>
											</div>
										</div>
									</div>
								</el-col>
								<el-col :span="12">
									<div class="fmag-box">
										<div class="fmag-content">
											<div class="fmag-head">
												<div class="fmag-title">
													公司的内推
												</div>
											</div>
											<div class="fmag-desc">
												<p>1，能不海投不要海投，北美的公司更注重内推</p>
												<p>2，足够多的公司的内推，拿到更多的面试机会</p>
											</div>
										</div>
									</div>
								</el-col>
							</el-row>
						</div>
					</div>
				</div>
				<div class="section">
					<div class="container">
						<div class="sec-head">
							<div class="sec-en">How to pass</div>
							<div class="sec-title">
								<div class="sec-inner">三，如何通过 FMAG 公司面试</div>
							</div>
						</div>
						<div class="sec-body">
							<div class="pass-step">
								<div class="pass-item">
									<div class="pass-item__hd">
										<div class="pass-item__index">第一步</div>
									</div>
									<div class="pass-item__bd">
										<div class="pass-item__content">
											<div class="pass-item__tt">搭建完整的"算法知识体系"和"设计知识体系"</div>
											<div class="pass-item__desc">
												<p>1，算法的数据结构基础，各种题型技巧的方法套路，足够多的题量</p>
												<p>2，面向对象设计OOD的案例分析，System Design系统设计体系框架</p>
											</div>
										</div>
									</div>
								</div>
								<div class="pass-item">
									<div class="pass-item__hd">
										<div class="pass-item__index">第二步</div>
									</div>
									<div class="pass-item__bd">
										<div class="pass-item__content">
											<div class="pass-item__tt">高效率的刷题</div>
											<div class="pass-item__desc">
												<p>1，在2023.8之前，至少需要500+题量作为基础，凭"实力"去面试，而不是凭"运气"去面试 </p>
												<p>2，做到见一题秒一题，完美达到FMAG公司算法面试的要求</p>
											</div>
										</div>
									</div>
								</div>
								<div class="pass-item">
									<div class="pass-item__hd">
										<div class="pass-item__index">第三步</div>
									</div>
									<div class="pass-item__bd">
										<div class="pass-item__content">
											<div class="pass-item__tt">全真模拟面试</div>
											<div class="pass-item__desc">
												<p>1，拿到面试机会后，熟悉北美大厂面试流程</p>
                        <p>2，不因临场表现不佳而错失拿到offer的机会</p>
											</div>
										</div>
								</div>
									</div>
							</div>
						</div>
					</div>
				</div>
				<div class="foot-section">
					<div class="container">
						<div class="zx-box">
							<div class="zx-content">
								<div class="zx-tt">
									免费北美CS求职规划
								</div>
								<div class="zx-desc">
									所有以上内容尽在《北美SDE求职旗舰班》，点击了解详细课程内容
								</div>
							</div>
							<router-link to="/course/7" class="zx-link">北美SDE求职旗舰班</router-link>
							<div class="zx-code">
								<div class="code-pic">
									<img src="../../assets/images/class/zx.png" alt="">
								</div>
								<div class="code-tt">扫一扫免费规划</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {

			};
		},
		created() {


		},
		mounted() {},
		methods: {

		},
	};
</script>

<style lang="scss" scoped>
	.container {
	    width: 1100px;
	    margin: 0 auto;
	}
	.top-section {
		height: 365px;
		background: url(../../assets/images/class/top.png) no-repeat center;
		background-size: cover;
	}

	.top-box {
		text-align: center;
		padding-top: 140px;

		.top-title {
      color: #fff;
			font-size: 50px;
		}

		.more-content {
      color: #fff;
			font-size: 16px;
			letter-spacing: 10px;
			margin-top: 14px;
		}

    .sec-title-me {
      font-size: 30px;
      margin-top: 14px;
      color: #F08080;
    }
	}

	.section {
		padding: 30px 0;
	}

	.sec-head {
		text-align: center;
		margin-bottom: 70px;

		.sec-title {
			font-size: 32px;
			color: #000000;
			//font-style: italic;
			line-height: 37px;
			margin-top: -54px;
			position: relative;
			display: flex;
			justify-content: center;

			.sec-inner {
				background-color: #fff;
			}

		}


		.sec-en {
			font-size: 73px;
			//color: #999999;
      color: #F5F5F5;
			line-height: 1;
			text-transform: uppercase;
		}

		.sec-desc {
			font-size: 16px;
			color: #000000;
			margin-top: 28px;
		}
	}

	.how-box {
		background-color: #fff;
		border-radius: 20px;
		padding: 20px;
		height: 280px;
		margin: 0 30px;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		.how-icon {
			width: 80px;
			height: 80px;
			border-radius: 50%;
		}

		.how-tt {
			font-size: 18px;
			color: #000000;
			margin-top: 30px;
		}

		.how-desc {
			margin-top: 20px;
			font-size: 16px;
			color: #000000;
		}
	}

	.fmag-box {
		padding: 32px 0 0 22px;
		height: 280px;
		background: url(../../assets/images/class/fmag_bg.png) no-repeat left top;

		.fmag-content {
			background-color: #fff;
			height: 100%;
			border-radius: 10px;
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.19);
			padding-top: 40px;

			.fmag-head {
				display: flex;

				.fmag-title {
					font-size: 18px;
					color: #fff;
					text-align: center;
					background: url(../../assets/images/class/fmag_tt.png) no-repeat center;
					line-height: 62px;
					width: 383px;
					background-size: cover;
				}
			}

			.fmag-desc {
				font-size: 16px;
				line-height: 36px;
				color: #000000;
				padding: 0 40px;
				margin-top: 35px;
			}
		}
	}

	.pass-step {
		margin: 0 30px;
		position: relative;

		.pass-item {
			display: flex;

			.pass-item__hd {
				width: 246px;
				padding: 30px 0 0 0;

				.pass-item__index {
					width: 136px;
					height: 68px;
					font-size: 18px;
					color: #fff;
					background: url(../../assets/images/class/pass_tag.png) no-repeat center;
					background-size: 100% 100%;
					text-align: center;
					padding-left: 20px;
					line-height: 46px;
				}
			}

			.pass-item__bd {
				flex: 1;
				display: flex;

				.pass-item__content {
				
					position: relative;
					background-color: #fff;
					border-radius: 20px;
					box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
					padding:22px 30px;
					display: flex;
					flex-direction: column;
					justify-content: center;

					.pass-item__tt {
						font-size: 18px;
						color: #d30900;
						line-height:24px;
						margin-bottom: 12px;
					}


					.pass-item__desc {
						color: #666666;
						font-size: 14px;
						line-height: 30px;
					}
				}
			}
		}
	}

	.pass-item__content::before {
		position: absolute;
		content: '';
		width: 0;
		height: 0;
		left: -24px;
		border-top: 14px solid transparent;
		border-right: 24px solid #efefef;
		border-bottom: 14px solid transparent;
		top: 50%;
		transform: translateY(-50%);
	}

	.pass-item__content::after {
		position: absolute;
		content: '';
		width: 0;
		height: 0;
		left: -23px;
		border-top: 13px solid transparent;
		border-right: 23px solid #fff;
		border-bottom: 13px solid transparent;
		top: 50%;
		transform: translateY(-50%);
	}

	.pass-step::before {
		position: absolute;
		content: '';
		left: 5px;
		width: 1px;
		top: 90px;
		bottom: 60px;
		border-left: 1px dashed #fc6b00;
	}

	.pass-item+.pass-item {
		margin-top: 40px;
	}

	.zx-box {
		height: 130px;
		background: url(../../assets/images/class/zx_bg.png) no-repeat left center #fff;
		background-size: cover;
		border-radius: 20px;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
		padding: 0 30px;
		display: flex;
		align-items: center;

		.zx-content {
			flex: 1;

			.zx-tt {
				font-size: 24px;
				color: #000000;
				font-weight: bold;
			}

			.zx-desc {
				font-size: 13px;
				color: #000000;
				margin-top: 10px;
			}
		}

		.zx-link {
			margin: 0 30px;
			font-size: 17px;
			color: #ffffff;
			line-height: 40px;
			padding: 0 22px;
			border-radius: 20px;
			background-image: linear-gradient(to right, #fe6e00, #cf0100);
		}

		.zx-code {
			text-align: center;

			.code-pic {
				font-size: 0;

				img {
					width: 76px;
					height: 76px;
				}
			}

			.code-tt {
				font-size: 13px;
				color: #000000;
				margin-top: 6px;
			}
		}
	}
	
	.solve-main{
		padding: 40px 0 90px;
	}
	.foot-section{
		position: fixed;
		bottom: 30px;
		width: 100%;
		left: 0;
	}
</style>
<style type="text/css">
	.solve-page{
		padding-bottom: 120px;
	}
	.solve-page + .house-footer{
		display: none;
	}
</style>